import React from 'react';
import Select from 'src/components/ui/select';
import { Box, Button } from '@chakra-ui/react';
import {
  useCloudflareDomainsQuery,
  usePurgeCloudflareCacheMutation,
} from 'src/api';
import useToast from 'src/hooks/useToast';

const Dashboard = () => {
  const [selectedDomain, setSelectedDomain] = React.useState('');
  const { data: domains, isLoading } = useCloudflareDomainsQuery();
  const [purgeCache, response] = usePurgeCloudflareCacheMutation();
  const toast = useToast();

  const handlePurgeCache = async () => {
    if (!selectedDomain) {
      toast('Please select a domain', 'error');
      return;
    }

    try {
      await purgeCache(selectedDomain).unwrap();
      toast('Cache flushed successfully', 'success');
    } catch (error: any) {
      toast(error?.data?.message || 'Something went wrong!', 'error');
    }
  };

  return (
    <div>
      <h1>Dashboard</h1>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          gap: 4,
          alignItems: 'flex-end',
        }}
      >
        <Select
          matchWidth
          label="Domain"
          maxWidth='300px'
          value={selectedDomain}
          options={domains?.map((domain: any) => ({
            label: domain.name,
            value: domain.id,
          })) || [{ label: 'wdqw', value: 'qwd' }]}
          placeholder='Select a domain'
          onChange={(value: any) => setSelectedDomain(value)}
          isLoading={isLoading}
        />
        <Button
          onClick={handlePurgeCache}
          isLoading={response.isLoading}
        >
          Flush Cache
        </Button>
      </Box>
    </div>
  );
};

export default Dashboard;
