import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api' : '/api';

const Api = createApi({
  reducerPath: 'api',
  tagTypes: [],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    credentials: 'include',
  }),
  endpoints: builder => ({
    login: builder.mutation({
      query: body => ({
        url: '/auth/login',
        method: 'POST',
        body,
      }),
    }),
    authenticate: builder.query<any, void>({
      query: () => `/auth/authenticate`,
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
    }),
    addOgWebsite: builder.mutation({
      query: body => ({
        url: '/og/generate-og-images',
        method: 'POST',
        body,
      }),
    }),
    updateOgWebsite: builder.mutation({
      query: (websiteId) => ({
        url: `/og/og-images/${websiteId}`,
        method: 'PUT',
      }),
    }),
    updateOgWebsiteImage: builder.mutation({
      query: ({websiteId, imageId}) => ({
        url: `/og/og-images/${websiteId}/update-image/${imageId}`,
        method: 'PUT',
      }),
    }),
    getOgWebsites: builder.query<any, void>({
      query: () => '/og/og-images',
    }),
    getOgImages: builder.mutation({
      query: websiteId => `/og/og-images/${websiteId}`,
    }),
    deleteOgWebsite: builder.mutation({
      query: websiteId => ({
        url: `/og/og-images/${websiteId}`,
        method: 'DELETE',
      }),
    }),
    addDnsServer: builder.mutation({
      query: body => ({
        url: '/dns-servers',
        method: 'POST',
        body,
      }),
    }),
    updateDnsServer: builder.mutation({
      query: ({id, body}) => ({
        url: `/dns-servers/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getDnsServers: builder.query<any, void>({
      query: () => '/dns-servers',
    }),
    deleteDnsServer: builder.mutation({
      query: dnsServerId => ({
        url: `/dns-servers/${dnsServerId}`,
        method: 'DELETE',
      }),
    }),
    getDnsServer: builder.mutation<any, string>({
      query: dnsServerId => `/dns-servers/${dnsServerId}`,
    }),
    importDnsServers: builder.mutation({
      query: body => ({
        url: '/dns-servers/import',
        method: 'POST',
        body,
      }),
    }),
    getAlert: builder.query<any, string>({
      query: siteKey => `/alert/${siteKey}`,
    }),
    updateAlert: builder.mutation({
      query: ({siteKey, body}) => ({
        url: `/alert/${siteKey}`,
        method: 'PUT',
        body,
      }),
    }),
    cloudflareDomains: builder.query<any, void>({
      query: () => `/cloudflare/domains`,
    }),
    purgeCloudflareCache: builder.mutation({
      query: zoneId => ({
        url: `/cloudflare/flush-cache/${zoneId}`,
        method: 'POST',
      }),
    }),
  }),
});

export default Api;
export const {
  useLoginMutation,
  useLazyAuthenticateQuery,
  useLogoutMutation,
  useGetOgWebsitesQuery,
  useGetOgImagesMutation,
  useAddOgWebsiteMutation,
  useUpdateOgWebsiteMutation,
  useDeleteOgWebsiteMutation,
  useUpdateOgWebsiteImageMutation,
  useAddDnsServerMutation,
  useUpdateDnsServerMutation,
  useGetDnsServersQuery,
  useDeleteDnsServerMutation,
  useGetDnsServerMutation,
  useImportDnsServersMutation,
  useGetAlertQuery,
  useUpdateAlertMutation,
  useCloudflareDomainsQuery,
  usePurgeCloudflareCacheMutation,
} = Api;
